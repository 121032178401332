import React, { FC, useEffect, useState } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import CommonTemplate from '../../components/templates/common_template'
import { EvalurationStarIcon } from '../../images/icons/star_icon'
import FlatButton from '../../components/buttons/flat_button'
import { useSnackBar } from '../../providers/snack_bar_provider'
import useCodes from '../../hooks/use_codes'
import { useForm } from 'react-hook-form'
import Enterprise from '../../model/Enterprise'
import { useModal } from 'react-hooks-use-modal'
import SelectEnterpriseModal from './components/select_enterprise_modal'
import { FormErrorMessage } from '../../constants/message'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { useLoading } from '../../providers/loading_provider'
import { Link, useParams } from 'react-router-dom'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { KuchikomiInputTextarea } from '../../components/kuchikomi/kuchikomi_input_textarea'
import StarEvaluationInput from '../../components/input/star_evaluation_input'
import useEnterpriseKuchikomi from '../detail/hooks/use_enterprise_kuchikomi'
import Head2 from '../../components/heads/head2'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import PagiNationCursor from '../../components/pagination/pagination_cursor'

type KuchikomiCreateFormProps = {
  message: string
  evaluation: number
  sdgsCategory: string | null
  isFollow: boolean
}

const KuchikomiCreatePage: FC = () => {
  const PAGE_SIZE = 20
  const pathParams = useParams<{ id: string }>()
  const isSp = useSpBreakPoint()
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()
  const { showLoading, hideLoading } = useLoading()
  const [codes] = useCodes()
  const [enterprise, setEnterprise] = useState<Enterprise | null>(null)
  const KuchikomiIndexSectionRef = React.createRef<HTMLDivElement>()
  const [kuchikomis, size, currentCursor, kuchikomiLoading, kuchikomiHandler] =
    useEnterpriseKuchikomi({ getLimit: PAGE_SIZE })
  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })

  useEffect(() => {
    if (pathParams.id) {
      getEnterprise(pathParams.id)
      kuchikomiHandler.load(pathParams.id)
    }
  }, [pathParams.id])

  useEffect(() => {
    reset({
      message: '',
      evaluation: 0,
      sdgsCategory: null,
      isFollow: false,
    })
    if (enterprise !== null && pathParams.id !== enterprise.id) {
      kuchikomiHandler.load(enterprise.id)
    }
  }, [enterprise])

  const getEnterprise = async (enterpriseId: string) => {
    await apiClient.enterprises.enterprisesDetail(enterpriseId).then((res) => {
      const data = res.data
      setEnterprise(data)
    })
  }

  const { register, handleSubmit, reset, watch, setValue, formState } =
    useForm<KuchikomiCreateFormProps>({
      defaultValues: {
        message: '',
        evaluation: 0,
        sdgsCategory: null,
        isFollow: false,
      },
      mode: 'onSubmit',
    })

  useEffect(() => {
    reset()
  }, [enterprise])

  const { errors } = formState

  const scrollToKuchikomiIndexSection = () => {
    if (KuchikomiIndexSectionRef.current) {
      const elementPosition =
        KuchikomiIndexSectionRef.current.getBoundingClientRect().top +
        window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  /**
   * 口コミを投稿する
   */
  const post = async (data: KuchikomiCreateFormProps) => {
    if (enterprise === null) {
      showSnackBar(['企業・団体を選択してください。'], 'error')
      return
    }
    showLoading()
    await apiClient.kuchikomis
      .kuchikomisCreate({
        enterpriseId: enterprise.id,
        evaluation: data.evaluation,
        message: data.message,
        sdgsCategory: data.sdgsCategory,
        isFollow: data.isFollow,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        showSnackBar(['クチコミを投稿しました。'], 'success')
        window.history.back()
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
      })
      .finally(() => {
        hideLoading()
      })
  }

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(pathParams.id ?? '', currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(pathParams.id ?? '', currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToKuchikomiIndexSection()
          kuchikomiHandler.load(pathParams.id ?? '', (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <>
      <CommonTemplate
        isPadding={!isSp}
        isSp={isSp}
        isShowPostButton={false}
        additonalTopNode
      >
        {enterprise === null ? (
          <LoadingSpinner className="mx-auto my-16" />
        ) : (
          <>
            {/* 企業詳細 */}
            <div className="bg-white relative rounded-xl mb-12">
              <div className="sm:flex p-4 sm:p-6 relative">
                {/* 左 */}
                <div className="sm:w-[calc(100%-200px)] sm:pr-12">
                  <p className="text-3xl font-bold mb-3">{enterprise.name}</p>
                  <div className="mb-3 text-sm">
                    {enterprise.businessCode
                      ? enterprise.businessCode.map((parent) => (
                          <p key={`parent-${parent.code}`}>
                            {`${parent.value}（${parent.child.value}）`}
                          </p>
                        ))
                      : '-'}
                    {enterprise.address && <p>{enterprise.address}</p>}
                  </div>
                  <p className="sm:mb-0 mb-4">{enterprise.description}</p>
                </div>

                {/* 右 */}
                <div className="w-[200px]">
                  <div className="mb-6">
                    <div className="flex items-center justify-between mb-2">
                      <div className="scale-150 relative left-6 bottom-[1px]">
                        <EvalurationStarIcon
                          evaluation={enterprise.evaluationAverage}
                        />
                      </div>
                      <span className="text-xl relative ">
                        {enterprise.evaluationAverage
                          ? enterprise.evaluationAverage.toFixed(2)
                          : '-'}
                      </span>
                    </div>
                    <p className="sm:text-center text-sm">
                      クチコミ件数{' '}
                      {enterprise.kuchikomiCount?.toLocaleString() ?? '-'}
                    </p>
                  </div>
                </div>

                <FlatButton
                  className="sm:absolute right-6 bottom-6 !w-[200px]"
                  onClick={open}
                >
                  企業・団体を変更する
                </FlatButton>
              </div>
              <hr className="border-2 border-main-gray" />
              <div className="p-4 sm:p-6">
                {/* この企業のクチコミを書く */}
                <div className="mb-4">
                  <p className="font-bold text-lg mb-2">
                    この企業・団体のクチコミを書く
                  </p>
                  <div className="mb-2">
                    <KuchikomiInputTextarea
                      placeholder="クチコミを書く"
                      watch={watch}
                      register={register('message', {
                        required: FormErrorMessage.required,
                      })}
                    />
                    {errors.message && (
                      <p className="error-message">{errors.message.message}</p>
                    )}
                  </div>
                  <div className="text-xs text-main-lightGray">
                    クチコミを投稿する前に、SDGsクチコミLodgeでは、書き込む上のでガイドラインを定めております。詳しくは
                    <Link to={'/guide/community'} className="underline">
                      ガイドライン
                    </Link>
                    をご確認ください。
                  </div>
                </div>
                {/* 評価 */}
                <div className="mb-6 sm:mb-4 flex items-center justify-between">
                  <div className="font-bold text-lg">評価</div>
                  <div>
                    <StarEvaluationInput
                      currentEvaluation={watch('evaluation')}
                      updateEvaluation={(evaluation: number) =>
                        setValue('evaluation', evaluation, {
                          shouldValidate: true,
                        })
                      }
                      inputOptions={register('evaluation', {
                        required: FormErrorMessage.required,
                        min: { value: 1, message: FormErrorMessage.required },
                      })}
                    />
                    {errors.evaluation && (
                      <p className="error-message mt-2">
                        {errors.evaluation.message}
                      </p>
                    )}
                  </div>
                </div>
                <input
                  className="hidden"
                  {...register('evaluation', {
                    required: '評価を入力してください',
                    min: { value: 1, message: '評価を入力してください' },
                  })}
                />
                {/* SDGs17の目標 */}
                <div className="mb-6 sm:mb-4 sm:flex items-center justify-between">
                  <div className="font-bold text-lg mb-2 sm:mb-0">
                    SDGs17の目標
                  </div>
                  <div>
                    <select
                      id="sdgs-select"
                      className="border border-main-lightGray rounded-md p-3"
                      {...register('sdgsCategory')}
                    >
                      <option value={''}>選択してください</option>
                      {codes.sdgsCategories.map((item, index) => {
                        return (
                          <option
                            key={`sdgs-code-${String(index)}`}
                            value={item.code}
                          >
                            {`${item.code}.${item.value}`}
                          </option>
                        )
                      })}
                    </select>
                    {errors.sdgsCategory && (
                      <p className="error-message">
                        {errors.sdgsCategory.message}
                      </p>
                    )}
                  </div>
                </div>
                {/* この企業をフォローする */}
                <label className="mb-4 sm:mb-2 block">
                  <input type="checkbox" className="mr-2" />
                  <span>この企業・団体をフォローする</span>
                </label>
              </div>
              <hr className="border-2 border-main-gray" />
              <div className="xs:flex p-4 items-center justify-center">
                <FlatButton
                  className="xs:mr-2 sm:mr-4 sm:mb-0 mb-4"
                  onClick={handleSubmit(post)}
                >
                  投稿する
                </FlatButton>
                <FlatButton
                  className="xs:ml-2 sm:ml-4 sm:mb-0 mb-4 bg-main-lightGray"
                  onClick={() => window.history.back()}
                >
                  戻る
                </FlatButton>
                {/* <FlatButton
                    className="!w-[200px] sm:!mr-6 !bg-main-lightGray"
                    onClick={tmpSave}
                  >
                    一時保存
                  </FlatButton> */}
              </div>
            </div>

            {/* クチコミ一覧 */}
            {!isSp && (
              <div className="mb-4" ref={KuchikomiIndexSectionRef}>
                <Head2
                  title="この企業のクチコミ"
                  subTitle={
                    kuchikomis.length > 0
                      ? `全件${String(size)}中 ${currentCursor + 1}~${
                          currentCursor + kuchikomis.length
                        }件`
                      : ''
                  }
                />
              </div>
            )}
            {!kuchikomiLoading ? (
              kuchikomis.length > 0 ? (
                <>
                  <div className="bg-white rounded-xl overflow-hidden py-4">
                    {kuchikomis.slice(0, 10).map((item, index, ary) => {
                      return (
                        <div key={`kuchikomi-${String(index)}`}>
                          <KuchikomiCard
                            kuchikomi={item}
                            borderBottom={index !== ary.length - 1}
                            isSp={isSp}
                            sendLike={kuchikomiHandler.like}
                            sendReply={kuchikomiHandler.reply}
                            deleteKuchikomi={kuchikomiHandler.delete}
                            onClickBookmark={kuchikomiHandler.bookmark}
                          />
                        </div>
                      )
                    })}
                  </div>
                  <div className="py-8">{paginationCursor}</div>
                </>
              ) : (
                <p className="text-center text-sm text-main-lightGray">
                  クチコミはありません
                </p>
              )
            ) : (
              <LoadingSpinner className="mx-auto mb-16" />
            )}
          </>
        )}
      </CommonTemplate>
      <Modal>
        <SelectEnterpriseModal
          close={close}
          select={setEnterprise}
        ></SelectEnterpriseModal>
      </Modal>
    </>
  )
  return content
}

export default KuchikomiCreatePage
