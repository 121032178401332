import React, { FC, useEffect } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import useCodes from '../../hooks/use_codes'
import useKuchikomiRanking from '../../hooks/use_kuchikomi_ranking'
import Head2 from '../../components/heads/head2'
import FilterButton from '../../components/buttons/filter_button'
import Select from '../../components/select/select'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import { RankingKuchikomiStat, RankingPeriod } from '../../clients/api_client'
import { RankingKuchikomiStatCode, RankingPeriodCode } from '../../model/Code'
import LoadingSpinner from '../../components/loading/loading_spinner'
import UserCard from '../../components/card/user_card'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import RankingTab from './components/tab/ranking_tab'
import { useSearchParams } from 'react-router-dom'
import useKuchikomiCountUserRanking from './hooks/use_kuchikomi_count_user_ranking'

const KuchikomiRankingPage: FC = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [codes] = useCodes()
  const [queryParam] = useSearchParams()

  const [
    ranking,
    currentPeriod,
    currentStat,
    currentGroup,
    currentGroupValue,
    size,
    currentCursor,
    rankingLoading,
    rankingHandler,
  ] = useKuchikomiRanking({
    period: RankingPeriod.All,
    stat: RankingKuchikomiStat.GoodCount,
    getLimit: PAGE_SIZE,
  })

  useEffect(() => {
    const period = queryParam.get('period')
    const stat = queryParam.get('stat')
    const group = queryParam.get('group')
    const groupValue = queryParam.get('groupValue')

    rankingHandler.search(
      period ? (period as RankingPeriod) : RankingPeriod.All,
      stat ? (stat as RankingKuchikomiStat) : RankingKuchikomiStat.GoodCount,
      group ?? undefined,
      groupValue ?? undefined
    )
  }, [])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const [userRanking, userRankingLoading] = useKuchikomiCountUserRanking({
    getLimit: 5,
  })

  // クチコミランキングを検索する
  const searchKuchikomiRanking = (
    period: RankingPeriod,
    stat: RankingKuchikomiStat,
    group?: string,
    groupValue?: string,
    cursor?: number
  ) => {
    const queryObj = Object.entries({
      period: period,
      stat: stat,
      group: group,
      groupValue: groupValue,
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => {
        return value !== undefined && value !== '' && value !== null
      })
      .map(([key, value]) => {
        return `${key}=${value}`
      })
      .join('&')
    window.history.pushState(
      null,
      '',
      window.location.pathname.split('?')[0] + '?' + queryObj
    )

    rankingHandler.search(period, stat, group, groupValue, cursor)
  }

  const kuchikomiRankingElem = (
    <div className="py-4 flex lg:justify-between xs:flex-wrap overflow-x-auto xs:overflow-x-visible">
      {userRanking.map((item, index) => {
        return (
          <div
            key={`user-ranking-${String(index)}`}
            className="w-[300px] xs:w-[calc(50%-1rem)] sm:w-[calc(33%-1rem)] lg:w-[calc(20%-1rem)] mx-4 xs:mr-4 xs:ml-0 lg:mr-0 mb-4 lg:mb-0"
          >
            <UserCard
              showRank={true}
              flex={false}
              user={item.user}
              rank={item.rank}
              imgSize="sm:w-[120px] sm:h-[120px] w-[100px] h-[100px]"
              className="!w-full"
            />
          </div>
        )
      })}
      {ranking.length === 0 && (
        <div className="text-center ">該当するクチコミがありません</div>
      )}
    </div>
  )

  const filterPeriodButtons = (
    <div className="flex justify-start flex-wrap">
      {codes.rankingPeriodCodes.map((period: RankingPeriodCode) => {
        return (
          <div key={`ranking-period-${period.code}`} className="pr-2 pb-2">
            <FilterButton
              label={period.label}
              value={period.code}
              isActive={period.code === currentPeriod.toString()}
              onClick={() =>
                searchKuchikomiRanking(
                  period.code,
                  currentStat,
                  currentGroup,
                  currentGroupValue
                )
              }
            />
          </div>
        )
      })}
    </div>
  )

  const filterStatButtons = (
    <div className="flex justify-start flex-wrap">
      {codes.rankingKuchikomiStatCodes.map((stat: RankingKuchikomiStatCode) => {
        return (
          <div key={`ranking-stat-${stat.code}`} className="pr-2 pb-2">
            <FilterButton
              label={stat.label}
              value={stat.code}
              isActive={stat.code === currentStat.toString()}
              onClick={() =>
                searchKuchikomiRanking(
                  currentPeriod,
                  stat.code,
                  currentGroup,
                  currentGroupValue
                )
              }
            />
          </div>
        )
      })}
    </div>
  )

  const filterGroupButtons = (
    <div className="flex justify-start flex-wrap">
      {/* <div className="pr-2 pb-2">
        <Select
          id="occupation"
          placeholder="職種カテゴリー"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            searchKuchikomiRanking(
              currentPeriod,
              currentStat,
              'occupation',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.occupationCodes.map((item) => {
            return {
              value: item.code,
              label: item.value,
            }
          })}
        />
      </div> */}
      <div className="pr-2 pb-2">
        <Select
          id="prefecture"
          placeholder="都道府県別"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            searchKuchikomiRanking(
              currentPeriod,
              currentStat,
              'prefecture',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.prefectures.map((prefecture) => {
            return {
              value: prefecture,
              label: prefecture,
            }
          })}
        />
      </div>
      <div className="pr-2 pb-2 max-w-full">
        <Select
          id="sdgs"
          placeholder="SDGsカテゴリー"
          currentValue={currentGroupValue ?? ''}
          currentGroup={currentGroup ?? ''}
          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
            searchKuchikomiRanking(
              currentPeriod,
              currentStat,
              'sdgs',
              e.currentTarget.value
            )
          }
          brankLabel="全て"
          items={codes.sdgsCategories.map((item) => {
            return {
              value: item.code,
              label: `${item.code}.${item.value}`,
            }
          })}
        />
      </div>
    </div>
  )

  const seachResultElem = (
    <>
      {ranking.length > 0 && (
        <div className="bg-white rounded-xl overflow-hidden py-4 max-w-[720px]">
          {ranking.map((item, index, ary) => {
            return (
              <div key={`ranking-${String(index)}`}>
                <KuchikomiCard
                  kuchikomi={item.kuchikomi}
                  sendLike={rankingHandler.like}
                  sendReply={rankingHandler.reply}
                  onClickBookmark={rankingHandler.bookmark}
                  borderBottom={index !== ary.length - 1}
                  deleteKuchikomi={rankingHandler.delete}
                />
              </div>
            )
          })}
        </div>
      )}
      {ranking.length === 0 && (
        <div className="text-center">該当するクチコミがありません</div>
      )}
    </>
  )

  const paginationCursor =
    ranking.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          searchKuchikomiRanking(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            currentCursor + PAGE_SIZE
          )
        }}
        prev={() => {
          scrollToIndexSection()
          searchKuchikomiRanking(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            currentCursor - PAGE_SIZE
          )
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          searchKuchikomiRanking(
            currentPeriod,
            currentStat,
            currentGroup,
            currentGroupValue,
            (pageNum - 1) * PAGE_SIZE
          )
        }}
      />
    ) : null

  const rankigTab = (
    <RankingTab activeIndex={1} isSp={isSp}>
      <p className="font-bold mb-1">ランキングの種類</p>
      <div className="mb-2">
        <div className="pb-1">{filterPeriodButtons}</div>
        <div className="pb-1">{filterStatButtons}</div>
      </div>
      <p className="font-bold mb-1">カテゴリーで絞る</p>
      <div>{filterGroupButtons}</div>
    </RankingTab>
  )

  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp} additonalTopNode>
      <div className="pb-4">
        {rankigTab}
        <div className="px-4 sm:px-0">
          <div className="pt-12">
            <Head2 title={`クチコミ件数ランキング`} />
          </div>
          {userRankingLoading ? (
            <LoadingSpinner className="mx-auto mb-16" />
          ) : (
            <div className="mb-16">{kuchikomiRankingElem}</div>
          )}

          <div className="mb-4 sm:mb-2">
            <Head2
              title={`クチコミランキング`}
              subTitle={
                ranking.length > 0
                  ? `全件${String(size)}中 ${currentCursor + 1}~${
                      currentCursor + ranking.length
                    }件`
                  : ''
              }
            />
          </div>
        </div>
      </div>
      {rankingLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div ref={IndexSectionRef} />
          <div className="pb-8">{seachResultElem}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
    </CommonTemplate>
  )

  return content
}

export default KuchikomiRankingPage
